import React from "react";
import theme from "../../theme";
import StartTour from "./StartTourButton";
import { ChevronDown, HelpCircle } from "lucide-react";

const HelpButton: React.FC<{
    currentRouteKey: any;
    darkTheme: any;
    user: any;
    authorizationService: any;
}> = ({ currentRouteKey, darkTheme, user, authorizationService }) => {
    return (
        <ul className={"dropdown-button tw-pr-3"} aria-haspopup="true">
            <li>
                <div className={"icon tw-flex"}>
                    <HelpCircle size={20} color={theme.colour.white} />
                    <ChevronDown
                        size={20}
                        color={theme.colour.white}
                        className={"caret-next-user"}
                    />
                </div>
                <ul className={"hover-menu"} aria-label="submenu">
                    <li>
                        {user && (
                            <StartTour
                                user={user}
                                currentRouteKey={currentRouteKey}
                            />
                        )}
                    </li>
                    <li>
                        <div className={"divider"} />
                    </li>
                    <li>
                        <div className={"text"}>
                            Need help or have questions?
                            <br />
                            Send us an email:
                            <br />
                            <a href="mailto:support@convergence.finance">
                                support@convergence.finance
                            </a>
                            .
                        </div>
                    </li>
                </ul>
            </li>
            <style jsx>{`
                .dropdown-button {
                    display: block;
                    position: relative;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    border: 0;
                    background: transparent;
                    color: ${theme.colour.white};
                    cursor: pointer;
                }
                .dropdown-button:hover {
                    color: ${theme.colour.gray200};
                }
                .icon {
                    font-size: 1.4rem;
                }
                .hover-menu {
                    display: none;
                    position: absolute;
                    top: 100%;
                    right: 0;
                    flex-direction: column;
                    z-index: 100;
                    min-width: 12rem;
                    text-align: left;
                    color: ${theme.colour.gray};
                    background-color: ${theme.colour.white};
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                    border-radius: 2px;
                    margin: 0;
                    padding: 0;
                }
                .hover-menu li {
                    padding: 0;
                    margin: 0;
                    display: block;
                }
                .hover-menu > li > :global(a) {
                    display: block;
                    padding: 0.5rem 1rem;
                }
                .hover-menu > li > :global(button) {
                    display: block;
                    padding: 0.5rem 1rem;
                    text-align: left;
                }
                .hover-menu .text {
                    padding: 0.8rem 1rem;
                }
                }
                .hover-menu .header {
                    padding: 0.8rem 1rem 0 1rem;
                    font-weight: bold;
                }
                .hover-menu > li .divider {
                    width: 100%;
                    margin: 0;
                    border-top: 1px solid ${theme.colour.gray300};
                }
                .dropdown-button:focus .hover-menu,
                .dropdown-button:active .hover-menu,
                .dropdown-button:hover .hover-menu {
                    display: flex;
                }
                .dropdown-button:focus-within .hover-menu {
                    display: flex;
                }
            `}</style>
        </ul>
    );
};

export default React.memo(HelpButton);
