import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { logEvent } from "../../../../../utils/Analytics";

const GET_TOUR_GUIDE = gql`
    query GetVirtualGuide($targetUrl: String!) {
        virtualGuide(targetUrl: $targetUrl) {
            id
            isActive
        }
    }
`;

const GET_USER_VIRTUAL_TOUR_GUIDE = gql`
    query GetUserVirtualGuide($virtualGuideId: String!) {
        userVirtualGuide(virtualGuideId: $virtualGuideId) {
            completed
            virtualGuideId
            skipped
            steps
        }
    }
`;

const hasVirtualGuide = (virtualTourGuideQuery) => {
    if (typeof virtualTourGuideQuery === "undefined") {
        return false;
    }

    if (typeof virtualTourGuideQuery.data === "undefined") {
        return false;
    }

    if (typeof virtualTourGuideQuery.data.virtualGuide === "undefined") {
        return false;
    }

    if (virtualTourGuideQuery.data.virtualGuide === null) {
        return false;
    }

    if (typeof virtualTourGuideQuery.data.virtualGuide.id === "undefined") {
        return false;
    }

    return true;
};

const StartTour = ({ user, currentRouteKey }) => {
    const targetUrl = currentRouteKey;
    const virtualTourGuideQuery = useQuery(GET_TOUR_GUIDE, {
        skip: !user,
        variables: {
            targetUrl,
        },
        fetchPolicy: "cache-first",
    });
    const apolloClient = virtualTourGuideQuery.client;
    const id = hasVirtualGuide(virtualTourGuideQuery)
        ? virtualTourGuideQuery.data.virtualGuide.id
        : null;

    const userVirtualTourGuideQuery = useQuery(GET_USER_VIRTUAL_TOUR_GUIDE, {
        skip: id === null,
        variables: {
            virtualGuideId: id,
        },
        fetchPolicy: "cache-first",
    });

    if (virtualTourGuideQuery.loading) {
        return <React.Fragment />;
    }

    if (virtualTourGuideQuery.error) {
        return <React.Fragment />;
    }

    const virtualTourGuide =
        virtualTourGuideQuery.data && virtualTourGuideQuery.data.virtualGuide
            ? virtualTourGuideQuery.data.virtualGuide
            : null;
    if (virtualTourGuide === null) {
        return <React.Fragment />;
    }

    if (userVirtualTourGuideQuery.loading) {
        return <React.Fragment />;
    }

    if (userVirtualTourGuideQuery.error) {
        return <React.Fragment />;
    }

    const userVirtualTourGuide =
        userVirtualTourGuideQuery.data &&
        userVirtualTourGuideQuery.data.userVirtualGuide
            ? userVirtualTourGuideQuery.data.userVirtualGuide
            : null;
    if (userVirtualTourGuide === null) {
        return <React.Fragment />;
    }

    const onClickStartGuide = (event) => {
        event.preventDefault();
        const query = GET_USER_VIRTUAL_TOUR_GUIDE;
        const data = userVirtualTourGuideQuery.data;
        apolloClient.writeQuery({
            query,
            data: {
                ...data,
                userVirtualGuide: {
                    ...data.userVirtualGuide,
                    completed: false,
                    skipped: false,
                },
            },
            variables: {
                virtualGuideId: id,
            },
        });
        logEvent(
            "Tour Guide",
            "Restarted Tour Guide",
            1,
            "User clicked a button to restart the tour guide.",
        );
    };

    return (
        <button
            className={"btn btn-block btn-link"}
            onClick={onClickStartGuide}
        >
            Start Tour Guide
        </button>
    );
};

export default StartTour;
