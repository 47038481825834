/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import Router from "./utils/Router";

const router = new Router();

/**
 * Redirects must be defined from most specific
 * to least specific (e.g. /test/1, /test) matching
 * route.
 *
 * Redirects are also checked before regular routes
 * are served.
 */
router
    .redirect(/^\/deal-database$/g, "/deal")
    .redirect(/^\/blended-finance-101$/g, "/blended-finance")
    .redirect(/^\/register-or-join-institution$/g, "/sign-up")
    .redirect(/^\/terms$/g, "/terms-of-use")
    .redirect(/^\/privacy$/g, "/privacy-policy")
    .redirect(/^\/knowledge-library$/g, "/knowledge")
    .redirect(/^\/knowledge-library\/([a-zA-Z0-9]+)/g, "/knowledge/$1/view")
    .redirect(/^\/about\?tab=news$/g, "/news-and-events")
    .redirect(
        /^\/design-funding\/grantee-detail\/([a-zA-Z0-9]+)/,
        "/design-funding/$1/view",
    )
    .redirect(
        /^\/news-detail\/([a-zA-Z0-9]+)$/,
        "/news-and-events/news/$1/view",
    )
    .redirect(/^\/about\?tab=faq$/g, "/frequently-asked-questions")
    .redirect(/^\/about\?tab=media$/g, "/in-the-media")
    .redirect(/^\/about\?tab=contact$/g, "/contact")
    .redirect(/^\/about\?tab=press$/g, "/press-releases")
    .redirect(
        /^\/press-detail\/([a-zA-Z0-9]+)$/,
        "/news-and-events/news/$1/view",
    )
    .redirect(/^\/about\?tab=careers$/g, "/careers")
    .redirect(/^\/workshops-and-trainings$/g, "/training")
    .redirect(
        /^\/workshops-and-trainings-detail\/([a-zA-Z0-9]+)$/,
        "/training/$1/view",
    )
    .redirect(
        /^\/institution\/([a-zA-Z0-9-]+)\/view\/historical-investments$/,
        "/institution/$1/view/historical-deal/investments",
    )
    .redirect(
        /^\/design-funding\/([a-zA-Z0-9]+)\/view$/,
        "/design-funding/grant-portfolio/$1/view",
    )
    .redirect(
        /^\/design-funding\/open-window\/check-application-status$/,
        "/design-funding/open-window/application/check-status",
    )
    .redirect(/^\/knowledge(.*)$/, "/resource$1")
    .redirect(/^\/training(.*)$/, "/capacity-building$1")
    .redirect(/^\/capacity-building\/library/, "/calendar/library")
    .redirect(/^\/capacity-building\/calendar/, "/calendar")
    .redirect(/^\/training\/([a-zA-Z0-9]+)\/view(.*)/, "/calendar/$1/view")
    .redirect(
        /^\/capacity-building\/([a-zA-Z0-9]+)\/view(.*)/,
        "/calendar/$1/view",
    )
    .redirect(
        /^\/capacity-building\/([a-zA-Z0-9]+)\/view\/documents(.*)/,
        "/calendar/$1/view/documents",
    )

    .redirect(/^\/settings$/, "/settings/profile")
    .redirect(/^\/deals\/historical/, "/historical/deal")
    .redirect(/^\/data-insights$/, "/historical/deal/summary-analysis")
    .redirect(/^\/data-insights\/historical-deals$/, "/historical/deal")
    .redirect(/^\/deals(.*)$/, "/deal$1")
    // .redirect(/^\/special-initiatives$/, "/themes-initiatives")
    .redirect(/^\/special-initiatives\/v\/ccfacility$/, "/ccfacility")
    .redirect(
        /^\/special-initiatives\/v\/catalytic-climate-finance-facility$/,
        "/ccfacility",
    )
    .redirect(/^\/institution$/, "/member")
    .redirect(/^\/news-and-events$/, "/news")
    .redirect(
        /^\/news-and-events\/news\/([a-zA-Z0-9]+)\/view(.*)/,
        "/news/$1/view",
    )
    .redirect(
        /^\/news-and-events\/events\/([a-zA-Z0-9]+)\/view(.*)/,
        "/calendar/$1/view",
    );

/**
 * Routes must be define sorted from specific-to-least specific
 * (e.g. /view/:id comes before /view)
 */
router
    .add("home", "/index", "/", true)

    // A
    .add("about", "/about/about", "/about", true)
    .add(
        "about-what-we-do",
        "/about/about/what-we-do",
        "/about/what-we-do",
        true,
    )
    .add("about-people", "/about/about/people", "/about/people", true)
    .add(
        "about-partners-funders",
        "/about/about/partners-funders",
        "/about/partners-funders",
        true,
    )
    .add("about-themes", "/themes-initiatives", "/about/themes", true)
    .add(
        "about-data-methodology",
        "/about/data-methodology",
        "/about/data-methodology",
        true,
    )
    .add("about-team", "/about/team/__id", "/about/team/:id", false)
    .add(
        "about-governance",
        "/about/governance/__id",
        "/about/governance/:id",
        false,
    )

    .add(
        "activate",
        "/activate/__activation_key",
        "/activate/:activation_key",
        false,
    )

    .add(
        "activate-trial",
        "/activate-trial/__activation_key",
        "/activate-trial/:activation_key",
        false,
    )

    // B
    .add("blended-finance", "/blended-finance", "/blended-finance", true)

    // C
    .add(
        "capacity-building",
        "/capacity-building/capacity-building",
        "/capacity-building",
        true,
    )
    // .add(
    //     "event-calendar-library",
    //     "/capacity-building/capacity-building",
    //     "/capacity-building/library",
    //     true
    // )
    // .add(
    //     "event-calendar",
    //     "/capacity-building/capacity-building",
    //     "/capacity-building/calendar",
    //     true
    // )
    // .add(
    //     "capacity-building-view",
    //     "/capacity-building/__id/view/view",
    //     "/capacity-building/:id/view",
    //     false
    // )
    // .add(
    //     "capacity-building-view-documents",
    //     "/capacity-building/__id/view/documents",
    //     "/calendar/:id/view/documents",
    //     false
    // )
    // .add(
    //     "capacity-building-view-related-articles",
    //     "/capacity-building/__id/view/related-articles",
    //     "/calendar/:id/view/related-articles",
    //     false
    // )

    .add(
        "event-calendar-library",
        "/capacity-building/capacity-building",
        "/calendar/library",
        true,
    )
    .add(
        "event-calendar",
        "/capacity-building/capacity-building",
        "/calendar",
        true,
    )
    .add(
        "capacity-building-view",
        "/capacity-building/__id/view/view",
        "/calendar/:id/view",
        false,
    )
    .add(
        "capacity-building-view-documents",
        "/capacity-building/__id/view/documents",
        "/calendar/:id/view/documents",
        false,
    )
    .add(
        "capacity-building-view-related-articles",
        "/capacity-building/__id/view/related-articles",
        "/calendar/:id/view/related-articles",
        false,
    )

    .add("careers", "/careers", "/careers", true)

    .add("ccfacility", "/ccfacility/ccfacility", "/ccfacility", true)

    .add(
        "ccfacility-details",
        "/ccfacility/ccfacility",
        "/ccfacility/details",
        true,
    )
    .add(
        "ccfacility-portfolio",
        "/ccfacility/ccfacility",
        "/ccfacility/portfolio",
        true,
    )
    .add("ccfacility-news", "/ccfacility/ccfacility", "/ccfacility/news", true)
    .add(
        "ccfacility-view-news",
        "/ccfacility/news/__id/view",
        "/ccfacility/news/:id/view",
        true,
    )
    .add(
        "ccfacility-view-events",
        "/ccfacility/events/__id/view",
        "/ccfacility/events/:id/view",
        true,
    )
    .add(
        "ccfacility-learning-hub",
        "/ccfacility/ccfacility",
        "/ccfacility/learning",
        true,
    )

    .add("contact", "/contact", "/contact", true)

    .add("course-view", "/courses/__id/view/view", "/course/:id/view", false)
    .add(
        "course-iframe-view",
        "/courses/__id/view/frame",
        "/course/:id/view/content",
        false,
    )
    .add(
        "course-certificate-view",
        "/courses/certificate",
        "/courses/certificate",
        false,
    )

    // D
    .add("dashboard", "/dashboard/dashboard", "/dashboard", false)

    .add("deals", "/deal/deals", "/deal", false)
    .add("early-deals", "/deal/deals", "/deal/early", false)
    .add(
        "deals-summary-analysis",
        "/deal/deals",
        "/deal/summary-analysis",
        false,
    )
    .add(
        "deals-matched-deals",
        "/deal/matched-deals",
        "/deal/matched-deals",
        false,
    )
    .add("deals-my-deals", "/deal/my-deals", "/deal/my-deals", false)
    .add(
        "deals-my-historical-deals",
        "/deal/my-historical-deals",
        "/deal/my-historical-deals",
        false,
    )
    // /deal/submit-deal*
    .add(
        "deals-submit-deal",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal",
        false,
    )
    .add(
        "deals-submit-deal-focus-areas",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/focus-areas",
        false,
    )
    .add(
        "deals-submit-deal-deal-structure",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/deal-structure",
        false,
    )
    .add(
        "deals-submit-deal-investors",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/investors",
        false,
    )
    .add(
        "deals-submit-deal-additional-details",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/additional-details",
        false,
    )
    .add(
        "deals-submit-deal-terms-and-conditions",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/terms-and-conditions",
        false,
    )
    .add(
        "deals-submit-deal-review",
        "/deal/submit-deal/submit-deal",
        "/deal/submit-deal/review",
        false,
    )

    // /deal/:id/view*
    .add("deals-view", "/deal/__id/view/view", "/deal/:id/view")
    .add(
        "deals-view-investors",
        "/deal/__id/view/view",
        "/deal/:id/view/investors",
    )
    .add(
        "deals-view-deal-structure",
        "/deal/__id/view/view",
        "/deal/:id/view/deal-structure",
    )
    .add(
        "deals-view-documents",
        "/deal/__id/view/view",
        "/deal/:id/view/documents",
    )
    .add(
        "deals-view-similar-deals",
        "/deal/__id/view/view",
        "/deal/:id/view/similar-deals",
    )
    .add(
        "deals-view-relevant-knowledge",
        "/deal/__id/view/view",
        "/deal/:id/view/relevant-resources",
    )

    .add(
        "deals-matched-investors",
        "/deal/__id/matched-investors",
        "/deal/:id/matched-investors",
    )

    // /deal/:id/edit*
    .add("deals-edit", "/deal/__id/edit/edit", "/deal/:id/edit", false)
    .add(
        "deals-edit-focus-areas",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/focus-areas",
        false,
    )
    .add(
        "deals-edit-deal-structure",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/deal-structure",
        false,
    )
    .add(
        "deals-edit-investors",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/investors",
        false,
    )
    .add(
        "deals-edit-additional-details",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/additional-details",
        false,
    )
    .add(
        "deals-edit-terms-and-conditions",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/terms-and-conditions",
        false,
    )
    .add(
        "deals-edit-review",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/review",
        false,
    )
    .add(
        "deals-edit-completed",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/completed",
        false,
    )
    .add(
        "deals-deal-matched-investors",
        "/deal/__id/edit/edit",
        "/deal/:id/edit/focus-areas",
        false,
    )

    // /design-funding*
    .add(
        "design-funding",
        "/design-funding/design-funding",
        "/design-funding",
        true,
    )
    .add(
        "design-funding-grant-portfolio",
        "/design-funding/design-funding",
        "/design-funding/grant-portfolio",
        true,
    )
    .add(
        "design-funding-impact",
        "/design-funding/design-funding",
        "/design-funding/portfolio-overview",
        true,
    )
    .add(
        "design-funding-market-acceleration",
        "/design-funding/market-acceleration",
        "/design-funding/market-acceleration",
        true,
    )
    .add(
        "design-funding-grant-portfolio-view-grant",
        "/design-funding/grant-portfolio/__id/view",
        "/design-funding/grant-portfolio/:id/view",
    )
    .add(
        "design-funding-open-window-application",
        "/design-funding/open-window/application/application",
        "/design-funding/open-window/application",
        true,
    )
    .add(
        "design-funding-open-window-application-optimy",
        "https://designfunding.convergence.finance/en/",
        "https://designfunding.convergence.finance/en/",
    )
    .add(
        "design-funding-open-window-application-check-status",
        "/design-funding/open-window/application/check-status",
        "/design-funding/open-window/application/check-status",
    )
    .add(
        "design-funding-open-window",
        "/design-funding/open-window/__pageSlug/open-window",
        "/design-funding/open-window/:pageSlug",
        true,
    )
    .add(
        "design-funding-open-window-fr",
        "/design-funding/open-window/__pageSlug/open-window",
        "/design-funding/open-window/:pageSlug/fr",
        true,
    )
    .add(
        "design-funding-open-window-es",
        "/design-funding/open-window/__pageSlug/open-window",
        "/design-funding/open-window/:pageSlug/es",
        true,
    )

    // E

    // F
    .add(
        "frequently-asked-questions",
        "/frequently-asked-questions",
        "/frequently-asked-questions",
        true,
    )

    // G

    // H
    .add(
        "historical-deals",
        "/historical/deal/deals",
        "/historical/deal",
        false,
    )
    .add(
        "historical-deals-summary-analysis",
        "/historical/deal/deals",
        "/historical/deal/summary-analysis",
        false,
    )

    .add(
        "historical-market-data-explorer",
        "/historical/deal/explorer",
        "/historical/deal/explorer",
        false,
    )

    // I
    .add("in-the-media", "/in-the-media", "/in-the-media", true)

    .add("institution", "/institution/institution", "/member")
    .add("institution-map", "/institution/map", "/institution/map")
    .add(
        "institution-access-denied",
        "/institution/access-denied",
        "/institution/access-denied",
    )
    .add(
        "institution-messages",
        "/institution/messages",
        "/institution/messages",
    )

    // /institution/register*
    .add(
        "institution-register",
        "/institution/register/register",
        "/institution/register",
    )
    .add(
        "institution-register-eligible-membership",
        "/institution/register/register",
        "/institution/register/eligible-membership",
    )
    .add(
        "institution-register-terms-and-conditions",
        "/institution/register/register",
        "/institution/register/terms-and-conditions",
    )
    .add(
        "institution-register-additional-details",
        "/institution/register/register",
        "/institution/register/additional-details",
    )
    .add(
        "institution-register-personal-details",
        "/institution/register/register",
        "/institution/register/personal-details",
    )
    .add(
        "institution-register-review",
        "/institution/register/register",
        "/institution/register/review",
    )

    .add(
        "institution-complete-preferences",
        "/institution/complete-preferences",
        "/institution/complete-preferences",
    )
    .add(
        "institution-pending-approval",
        "/institution/status/pending-approval",
        "/institution/status/pending-approval",
    )
    .add(
        "institution-denied",
        "/institution/status/denied",
        "/institution/status/denied",
    )
    .add(
        "institution-suspended",
        "/institution/status/suspended",
        "/institution/status/suspended",
    )
    .add(
        "institution-reactivated",
        "/institution/status/reactivated",
        "/institution/status/reactivated",
    )
    .add(
        "institution-compliance-review",
        "/institution/status/compliance-review",
        "/institution/status/compliance-review",
    )
    .add(
        "institution-unknown-status",
        "/institution/status/unknown-status",
        "/institution/status/unknown-status",
    )

    // /institution/:id/view*
    .add(
        "institution-view",
        "/institution/__id/view/view",
        "/institution/:id/view",
    )
    .add(
        "institution-view-historical-deals-investments",
        "/institution/__id/view/view",
        "/institution/:id/view/historical-deals/investments",
    )
    .add(
        "institution-view-historical-deals-deals-sponsored",
        "/institution/__id/view/view",
        "/institution/:id/view/historical-deals/deals-sponsored",
    )
    .add(
        "institution-view-historical-deals-funds-managed",
        "/institution/__id/view/view",
        "/institution/:id/view/historical-deals/funds-managed",
    )
    .add(
        "institution-view-fundraising-deals",
        "/institution/__id/view/view",
        "/institution/:id/view/fundraising-deals",
    )

    // /investor-data
    .add("investor-data", "/investor-data/investor-data", "/investor-data")
    .add(
        "investor-data-summary-analysis",
        "/investor-data/summary-analysis",
        "/investor-data/summary-analysis",
    )
    .add(
        "investor-data-view-institution",
        "/investor-data/__id/view/view",
        "/investor-data/:id/view",
    )
    .add(
        "investor-data-view-institution-funds-managed",
        "/investor-data/__id/view/view",
        "/investor-data/:id/view/funds-managed",
    )
    .add(
        "investor-data-view-institution-deals-sponsored",
        "/investor-data/__id/view/view",
        "/investor-data/:id/view/deals-sponsored",
    )

    .add("impersonate", "/impersonate/__id", "/impersonate/:id")

    // J

    // K
    .add("knowledge", "/knowledge/knowledge", "/resource", true)
    .add("knowledge-view", "/knowledge/__id/view", "/resource/:id/view")
    .add(
        "knowledge-view-relevant-deals",
        "/knowledge/__id/view/relevant-deals",
        "/resource/:id/view/relevant-deals",
    )

    // L
    .add("logout", "/logout", "/logout")

    .add("login", "/login", "/login")

    // M
    .add("membership", "/membership", "/membership", true)
    .add("member", "/member/member", "/member")

    // N
    .add("news-and-events", "/news-and-events/news-and-events", "/news", true)
    .add(
        "member-spotlight",
        "/news-and-events/member-spotlight",
        "/member-spotlight",
        true,
    )
    .add(
        "member-spotlight-view-spotlight",
        "/news-and-events/member-spotlight/__id/view",
        "/member-spotlight/:id/view",
        true,
    )
    .add(
        "news-and-events-view-all-events",
        "/news-and-events/view-all-events",
        "/news-and-events/view-all-events",
        true,
    )
    .add(
        "news-and-events-view-news",
        "/news-and-events/news/__id/view",
        "/news/:id/view",
    )
    .add(
        "news-and-events-view-events",
        "/news-and-events/events/__id/view",
        "/calendar/:id/view",
    )

    // O
    .add(
        "online-learning",
        "/capacity-building/capacity-building",
        "/capacity-building#online-learning",
        true,
    )

    // P
    .add("privacy-policy", "/privacy-policy", "/privacy-policy", true)

    .add("press-releases", "/press-releases", "/press-releases", true)

    .add("page", "/page/__slug", "/page/:slug")

    // Q
    .add("qrapp", "/qr/__from", "/qr/:from")

    // R
    .add(
        "reset-password",
        "/reset-password/reset-password",
        "/reset-password",
        true,
    )
    .add(
        "reset-password-verify",
        "/reset-password/__verification_key",
        "/reset-password/:verification_key",
    )

    // S
    .add(
        "scale-working-group",
        "/scale-working-group/scale-working-group",
        "/scale-working-group",
    )
    .add(
        "scale-working-group-view",
        "/scale-working-group/__id/view",
        "/scale-working-group/:id/view",
    )

    .add(
        "securities-registration",
        "/securities-registration",
        "/securities-registration",
        true,
    )

    .add("sign-up", "/sign-up", "/sign-up", true)

    .add("settings-profile", "/settings/profile", "/settings/profile")
    .add(
        "settings-institution",
        "/settings/institution",
        "/settings/institution",
    )
    .add(
        "sobf-2024",
        "/resource/state-of-blended-finance-2024/view",
        "/resource/state-of-blended-finance-2024/view",
    )
    .add(
        "sobf-2024-climate",
        "/resource/state-of-blended-finance-2024-climate-edition/view",
        "/resource/state-of-blended-finance-2024-climate-edition/view",
    )

    // /special-initiatives*
    .add(
        "special-initiatives",
        "/themes-initiatives",
        "/special-initiatives",
        true,
    )
    .add(
        "special-initiatives-initiative",
        "/special-initiatives/__pageSlug/initiative",
        "/special-initiatives/:pageSlug",
        true,
    )
    .add(
        "special-initiatives-very-special-initiative",
        "/special-initiatives/__pageSlug/initiative",
        "/special-initiatives/v/:pageSlug",
        true,
    )
    .add(
        "special-initiatives-very-special-initiative-details",
        "/special-initiatives/__pageSlug/initiative-details",
        "/special-initiatives/v/:pageSlug/details",
        true,
    )
    // We can add this back if we have a special initiative with deals again
    // .add(
    //     "special-initiatives-deals",
    //     "/special-initiatives/__pageSlug/initiative",
    //     "/special-initiatives/:pageSlug/deals",
    //     true
    // )
    .add(
        "survey",
        "https://www.surveymonkey.com/r/GFQMVJY?utm_source=mailchimp&utm_medium=email&utm_campaign=membersurvey22",
        "https://www.surveymonkey.com/r/GFQMVJY?utm_source=mailchimp&utm_medium=email&utm_campaign=membersurvey22",
        false,
    )

    // T
    .add("terms-of-use", "/terms-of-use", "/terms-of-use", true)

    .add("training", "/training/training", "/training", true)
    .add(
        "training-view",
        "/training/__id/view/view",
        "/training/:id/view",
        false,
    )
    .add(
        "training-view-documents",
        "/training/__id/view/documents",
        "/training/:id/view/documents",
        false,
    )
    .add(
        "training-view-related-articles",
        "/training/__id/view/related-articles",
        "/training/:id/view/related-articles",
        false,
    )

    // U

    // V
    .add(
        "verify-email",
        "/verify-email/__verification_key",
        "/verify-email/:verification_key",
    )

    // W
    .add(
        "wash-working-group",
        "/wash-working-group/wash-working-group",
        "/wash-working-group",
    )
    .add(
        "wash-working-group-view",
        "/wash-working-group/__id/view",
        "/wash-working-group/:id/view",
    );

// X

// Y

// Z

export default router;
