/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import gql from "graphql-tag";

const ROLE_PERMISSION_FIELDS = gql`
    fragment RolePermissionFields on ResourceRolePermission {
        role
        permissionMask
    }
`;

const RESOURCE_FIELDS = gql`
    fragment ResourceFields on Resource {
        id
        createdAt
        lastModifiedAt
        isDeleted
        isPublished
        accessType
        ownerId
        resourceFolderId
        resourceType
        tags
    }
`;

const RESOURCE_VERSION_FIELDS = gql`
    fragment ResourceVersionFields on ResourceVersion {
        id
        createdAt
        lastModifiedAt
        resourceId
        ownerId
    }
`;

const RESOURCE_CONTENT_FIELDS = gql`
    fragment ResourceContentFields on ResourceContent {
        title
        description
        author
        publicationDate
        seoTitle
        seoDescription
        externalUrl
        publicFileId
        privateFileId
        bannerImageId
        instrumentFocus
        regionFocus
        sectorFocus
        subSectorFocus
        themeFocus
    }
`;

const RESOURCE_FOLDER_FIELDS = gql`
    fragment ResourceFolderFields on ResourceFolder {
        id
        createdAt
        lastModifiedAt
        isDeleted
        name
        accessType
        parentId
        description
    }
`;

export const GET_RESOURCE = gql`
    query GetResource($id: String!) {
        resource(id: $id) {
            ...ResourceFields
            roles {
                ...RolePermissionFields
            }
            resourceFolder {
                ...ResourceFolderFields
            }
            latestVersion {
                ...ResourceVersionFields
                content {
                    ...ResourceContentFields
                }
            }
        }
    }
    ${RESOURCE_FIELDS}
    ${ROLE_PERMISSION_FIELDS}
    ${RESOURCE_FOLDER_FIELDS}
    ${RESOURCE_VERSION_FIELDS}
    ${RESOURCE_CONTENT_FIELDS}
`;

export const GET_RESOURCE_BY_CONTENTFUL_ID = gql`
    query GetResourceByContentfulId($id: String!, $resourceFolderName: String) {
        resourceByContentfulId(
            id: $id
            resourceFolderName: $resourceFolderName
        ) {
            ...ResourceFields
            roles {
                ...RolePermissionFields
            }
            resourceFolder {
                ...ResourceFolderFields
            }
            latestVersion {
                ...ResourceVersionFields
                content {
                    ...ResourceContentFields
                }
            }
        }
    }
    ${RESOURCE_FIELDS}
    ${ROLE_PERMISSION_FIELDS}
    ${RESOURCE_FOLDER_FIELDS}
    ${RESOURCE_VERSION_FIELDS}
    ${RESOURCE_CONTENT_FIELDS}
`;

export const PAGE_SEARCH_RESULTS = gql`
    query PageSearchResults(
        $first: Int
        $after: String
        $filters: ResourceFilter
        $sort: SearchSortInput
    ) {
        pageSearchResults(
            first: $first
            after: $after
            filters: $filters
            sort: $sort
        ) {
            total_count
            pageInfo {
                start_cursor
                end_cursor
                has_next_page
            }
            edges {
                cursor
                relevance
                node {
                    id
                    title
                    status
                    searchIndex
                    contentTypeId
                }
            }
        }
    }
`;
