/*
 * This file is part of the Convergence API Server.
 *
 * (c) Convergence <https://convergence.finance/>
 */

import fuzzysearch from "fuzzysearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { BRAND_COLOURS } from "../../constants";
import SearchHistoryDropdown from "./SearchHistoryDropdown";
import { withRouter } from "next/router";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { WithRouterProps } from "next/dist/client/with-router";
import { faTimes } from "@fortawesome/fontawesome-free-solid";

interface Props extends WithRouterProps {
    showSearch;
    onClickShow;
    onClickClose;
    value;
    buttonClassName;
    inputClassName;
    onChange;
    showHistory;
    autoFocus?: boolean;
}

type State = {
    showSearch;
    showHistory;
};
class SearchButton extends React.Component<Props, State> {
    static propTypes = {
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onClickShow: PropTypes.func,
        onClickClose: PropTypes.func,
        showSearch: PropTypes.bool,
        buttonClassName: PropTypes.string,
        inputClassName: PropTypes.string,
        autoFocus: PropTypes.bool
    };
    historyStorageName: string;
    searchBox: any;

    constructor(props) {
        super(props);
        this.state = {
            showSearch: this.props.showSearch,
            showHistory: false
        };
        this.historyStorageName = `search-history-${this.props.router?.pathname}`;
        this.searchBox = null;
    }

    onFocus = () => {
        this.setState({ showHistory: this.props.showHistory });
    };

    onBlur = () => {
        this.setState({ showHistory: false });
    };

    unFocusIfEnterOrEscape = (event) => {
        if (event.key === "Enter" || event.key === "Escape") {
            this.setState({ showHistory: false });
        } else {
            this.setState({ showHistory: this.props.showHistory });
        }
    };

    setLocalStorageSearchHistory = (a) => {
        if (typeof localStorage == "undefined") return false;
        localStorage.setItem(this.historyStorageName, JSON.stringify(a));
        return true;
    };

    getLocalStorageSearchHistory = () => {
        if (typeof localStorage == "undefined") return [];
        try {
            return JSON.parse(localStorage.getItem(this.historyStorageName));
        } catch {
            this.setLocalStorageSearchHistory([]);
            return [];
        }
    };

    saveKeyword = (keyword, selected = false) => {
        if (!keyword || !(typeof keyword === "string")) return;
        const trimmedKeyword = keyword.trim();
        if (trimmedKeyword.length < 4) return;
        let history = this.getLocalStorageSearchHistory();

        if (!history || !Array.isArray(history)) {
            history = [trimmedKeyword];
        } else if (
            !(
                history.length > 0 &&
                typeof history[0] === "string" &&
                history[0]
                    .toLocaleLowerCase()
                    .includes(trimmedKeyword.toLocaleLowerCase())
            )
        ) {
            history.unshift(trimmedKeyword);
        }

        if (
            selected &&
            history.length > 1 &&
            fuzzysearch(history[1].toLowerCase(), history[0].toLowerCase())
        ) {
            // if the previous history entry [1] was used to find
            //   the selected history entry ([0]), remove it.
            history.splice(1, 1);
        }
        history = history.filter(
            (query, index) =>
                index == 0 ||
                !keyword.toLowerCase().includes(query.toLowerCase())
        );
        if (history.length > 50) history.pop();
        this.setLocalStorageSearchHistory(history);
    };

    onChange = (keyword) => {
        this.saveKeyword(keyword);
        this.props.onChange(keyword); // TODO!! this needs a refactor: it should only provide the query/value/keyword
    };

    onSelectKeyword = (keyword) => {
        this.saveKeyword(keyword, true);
        this.props.onChange(keyword);
    };

    onClearHistory = () => {
        this.setLocalStorageSearchHistory([]);
        this.setState({ showHistory: false });
    };

    onClickShow = (event) => {
        this.setState({ showSearch: true }, () => {
            this.searchBox.focus();
            if (this.props.onClickShow) {
                this.props.onClickShow(event);
            }
        });
    };

    onClickClose = (event) => {
        this.setState({ showSearch: false }, () => {
            if (this.props.onClickClose) {
                this.props.onClickClose(event);
            }
        });
    };

    render() {
        let { value, buttonClassName, inputClassName, autoFocus } = this.props;
        const { showSearch } = this.state;
        const history = this.getLocalStorageSearchHistory(); // TODO ugly here: move to localstate. on Mount

        if (!buttonClassName) {
            buttonClassName = "btn btn-primary";
        }

        if (!inputClassName) {
            inputClassName = "form-control border-0";
        }

        // if (showSearch) {
        return (
            <React.Fragment>
                <style jsx>
                    {`
                            .search-box {
                                position: relative;
                                display: inline-block;
                                padding: 4px;
                                // margin-top: 1.5rem;
                                margin-bottom: 1rem;
                            }
                            .search-box .input-group {
                                border-radius: 4px;
                                border: 1px solid hsl(0, 0%, 80%);
                                height: 38px;
                            }
                            
                            .search-box input {
                                font-size: 12.75px;
                                padding: 2px 8px;
                            }

                            .search-box .icon {
                                cursor: pointer;
                                margin: 0;
                                padding: 0 5px;
                                background: none;
                                border: 0;
                                color: ${BRAND_COLOURS.gray};
                            }
                            .search-box .icon:hover {
                                color: ${BRAND_COLOURS.dark};
                            }
                            .input-group-append {
                                // background-color: ${BRAND_COLOURS.secondary};
                                background-color: hsl(0, 0%, 80%);
                            }
                        `}
                </style>
                <div className="search-box">
                    {this.state.showHistory && (
                        <SearchHistoryDropdown
                            // @ts-ignore POSSIBLE ERROR FOUND no className
                            className="hist-drop"
                            keyword={value}
                            history={history}
                            onSelect={this.onSelectKeyword}
                            onClear={this.onClearHistory}
                        />
                    )}
                    <div className={"input-group"}>
                        <input
                            type="text"
                            className={inputClassName}
                            id="search"
                            value={value}
                            name="keyword"
                            onChange={(event) => {
                                this.onChange(event.target.value);
                            }}
                            placeholder="Search"
                            ref={(input) => (this.searchBox = input)}
                            onKeyDown={this.unFocusIfEnterOrEscape}
                            onClick={this.onFocus}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            autoComplete="off"
                            autoFocus={autoFocus}
                        />
                        {value && (
                            <span
                                className="icon d-flex align-items-center p-2"
                                onClick={this.onClickClose}
                            >
                                <FontAwesomeIcon
                                    icon={faTimes as IconProp}
                                    // color="white"
                                    size={"sm"}
                                />
                            </span>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(SearchButton);
