import {
    Lock,
    Search,
    X,
    Library,
    Newspaper,
    Calendar,
    HandCoins,
    DatabaseZap,
    UserRound
} from "lucide-react";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetTrigger
} from "../../../../ui/sheet";
import flatten from "lodash.flatten";

import PagedListFilterBar from "../../../../general/PagedListFilterBar";
import {
    b64DecodeUnicode,
    b64EncodeUnicode
} from "../../../../../utils/CommonUtils";
import { useState } from "react";
import { useQuery } from "react-apollo";
import { PAGE_SEARCH_RESULTS } from "../../../../../graphql/query/SearchQuery";
import theme from "../../theme";
import Link from "../../../../theme/3.0/Link";
import { Input } from "reactstrap";
import { fakeSearchResults } from "./fakeSearchResults";
import {
    PAGE_EVENTS,
    PAGE_KNOWLEDGE,
    PAGE_NEWS
} from "../../../../../constants";

export const toGraphQLFilters = (filters) => {
    let graphQLFilters = {};
    const resourceTypeMap = {
        "Case Studies": "CaseStudies",
        "Policy and Research Reports": "PolicyAndResearchReports",
        "Primers and Toolkits": "PrimersAndToolkits",
        "Standardized Template": "StandardizedTemplate",
        "Data Briefs": "DataBriefs"
    };
    for (let filterKey in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, filterKey)) {
            let filterValue = filters[filterKey];
            switch (filterKey) {
                case "sectorsAndSubSectors": {
                    let sectors = Object.keys(filterValue);
                    let subSectors = flatten(Object.values(filterValue));
                    if (subSectors.length > 0) {
                        graphQLFilters["subSectorFocus"] = subSectors;
                    }
                    if (sectors.length > 0) {
                        graphQLFilters["sectorFocus"] = sectors;
                    }
                    break;
                }
                case "resourceType": {
                    const resourceTypes = filterValue.map(
                        (t) => resourceTypeMap[t]
                    );
                    graphQLFilters["resourceType"] = resourceTypes;
                    break;
                }
                case "regionFocus": {
                    const regionFocus = filterValue.map((region) => {
                        if ((region = "Latin America and the Caribbean"))
                            return "Latin America & the Caribbean";
                        return region;
                    });
                    graphQLFilters["regionFocus"] = regionFocus;
                    break;
                }
                default: {
                    graphQLFilters[filterKey] = filterValue;
                }
            }
        }
    }
    return graphQLFilters;
};

const getQueryData = () => {
    let variables = {
        keyword: "",
        // TS CODE CHANGE filters changed from [] to ""
        filters: "",
        // TS CODE CHANGE added sort
        sort: ""
    };
    if (process.browser) {
        let queryVars = window.location.search.replace("?", "&").split("&");
        queryVars.map((query) => {
            if (query.length <= 0) {
                return;
            }

            let split = query.split("=");

            if (split[0] && split[1]) {
                variables[split[0]] = decodeURIComponent(split[1]);
            }
        });

        if (variables.filters) {
            try {
                variables.filters = JSON.parse(
                    b64DecodeUnicode(decodeURIComponent(variables.filters))
                );
            } catch (e) {
                //
            }
        }

        if (variables.sort) {
            try {
                variables.sort = JSON.parse(
                    b64DecodeUnicode(decodeURIComponent(variables.sort))
                );
            } catch (e) {
                //
            }
        }

        if (variables.keyword) {
            try {
                variables.keyword = b64DecodeUnicode(
                    decodeURIComponent(variables.keyword)
                );
            } catch (e) {
                //
            }
        }
    }
    return variables;
};
const dataFromQuery = getQueryData();
const initialData = {
    defaultFilter: "dealTypes",
    keyword: "",
    // keyword: dataFromQuery.keyword,
    // TS FOUND ERROR:
    // dataFromQuery.filters is a string
    filters: {
        // @ts-ignore TS TODO
        ...dataFromQuery.filters,
        // resourceFolder: "Resources",
        keyword: ""
        // keyword: dataFromQuery.keyword
    }
};
const defaultFilter = "instrumentFocus";
const availableFilters = {
    // instrumentFocus: DEAL_TYPES_FILTER,
    // sectorsAndSubSectors: SECTORS_AND_SUB_SECTORS_FILTER,
    // regionFocus: REGIONS_FILTER,
    // themeFocus: IMPACT_THEMES_FILTER,
    // resourceType: KNOWLEDGE_TYPES_FILTER
};

const setPushState = (filters, keyword) => {
    let serializedFilters = filters
        ? b64EncodeUnicode(JSON.stringify(filters))
        : null;
    let serializedKeyword =
        keyword.length > 0 ? b64EncodeUnicode(keyword) : null;

    let queryString = [];
    if (serializedKeyword) {
        queryString.push(`keyword=${encodeURIComponent(serializedKeyword)}`);
    }

    if (serializedFilters) {
        queryString.push(`filters=${encodeURIComponent(serializedFilters)}`);
    }

    window.history.pushState(
        {},
        "",
        queryString.length > 0 ? `?${queryString.join("&")}` : "?"
    );
};

export function SearchSheet({ user, authorizationService, iconOnly }) {
    const [open, setOpen] = useState(false);
    const [state, setState] = useState({
        // disableLoadMore: first ? true : false,
        filters: initialData.filters,
        keyword: initialData.keyword
    });
    const { loading, error, data, refetch, fetchMore } = useQuery(
        PAGE_SEARCH_RESULTS,
        {
            variables: {
                filters: toGraphQLFilters({
                    ...initialData.filters
                }),
                sort: {
                    column: "publicationDate",
                    direction: "desc"
                }
            },
            fetchPolicy: "cache-and-network",
            skip: !open
        }
    );

    const updateState = (newState) => {
        const filters = {
            ...state.filters,
            ...newState.filters,
            tags: state.filters?.tags ?? []
        };
        setState({
            ...state,
            ...newState,
            filters
        });
        const keyword =
            typeof newState.keyword === "string"
                ? newState.keyword
                : state.keyword;

        try {
            // @ts-ignore POSSIBLE ERROR FOUND. TS TODO quite messy
            refetch({
                filters: toGraphQLFilters({
                    ...filters,
                    keyword
                })
            }).then(() => {});
        } catch (error) {}
    };
    const deals = data?.pageSearchResults?.edges?.filter(
        ({ node }) => node.searchIndex === "deal-v2"
    );
    const liveDeals = deals?.filter(({ node }) => node.status === "Approved");
    const historicalDeals = deals?.filter(
        ({ node }) => node.status === "Historical"
    );
    const resources = data?.pageSearchResults?.edges?.filter(
        ({ node }) =>
            (node.searchIndex === "resource" ||
                node.searchIndex === "resource-v2") &&
            node.contentTypeId === PAGE_KNOWLEDGE
    );
    const news = data?.pageSearchResults?.edges?.filter(
        ({ node }) =>
            (node.searchIndex === "resource" ||
                node.searchIndex === "resource-v2") &&
            node.contentTypeId === PAGE_NEWS
    );
    const events = data?.pageSearchResults?.edges?.filter(
        ({ node }) =>
            (node.searchIndex === "resource" ||
                node.searchIndex === "resource-v2") &&
            node.contentTypeId === PAGE_EVENTS
    );
    const historicalInstitutions = data?.pageSearchResults?.edges?.filter(
        ({ node }) => node.searchIndex === "historical-institution"
    );
    const institutions = data?.pageSearchResults?.edges?.filter(
        ({ node }) => node.searchIndex === "institution"
    );
    return (
        <Sheet open={open} onOpenChange={setOpen}>
            <SheetTrigger className="tw-h-full">
                {/* <Button variant="outline">Open</Button> */}
                <span className="tw-flex tw-flex-row tw-relative tw-justify-self-end tw-text-white xl:tw-text-user tw-h-auto">
                    {iconOnly ? null : (
                        <span className="tw-invisible xl:tw-visible tw-flex">
                            <Input
                                placeholder="search"
                                size={20}
                                className="tw-rounded tw-h-auto tw-max-w-28"
                            />
                        </span>
                    )}
                    <Search
                        size={iconOnly ? 30 : 22}
                        // color={theme.colour.gray}
                        className={
                            iconOnly
                                ? "tw-flex"
                                : "tw-flex tw-justify-center tw-absolute tw-h-full xl:tw-h-auto tw-right-0 xl:tw-right-2 tw-top-[-2px] xl:tw-top-[9.5px]"
                        }
                    />
                </span>
            </SheetTrigger>
            <SheetContent
                className="sheet tw-rounded tw-z-[101] tw-bg-primary tw-gap-2 tw-justify-between tw-items-start tw-overflow-hidden tw-flex tw-flex-col"
                side="top"
            >
                <SheetHeader className="tw-w-full tw-justify-between tw-flex tw-flex-row">
                    <SheetTitle>Search</SheetTitle>
                    <SheetClose className="tw-absolute tw-right-4 tw-top-4 tw-rounded-sm tw-opacity-70 tw-ring-offset-background tw-transition-opacity hover:tw-opacity-100 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-ring focus:tw-ring-offset-2 disabled:tw-pointer-events-none data-[state=open]:tw-bg-secondary">
                        <X
                            color={theme.colour.primaryDark}
                            className="tw-h-4 tw-w-4"
                        />
                        <span className="tw-sr-only">Close</span>
                    </SheetClose>
                </SheetHeader>
                <div className="tw-gap-4 tw-py-4">
                    <div className="tw-items-center tw-gap-4">
                        <PagedListFilterBar
                            toGraphQLFilters={toGraphQLFilters}
                            availableFilters={availableFilters}
                            defaultFilter={defaultFilter}
                            initialFilters={initialData.filters}
                            updateState={updateState}
                            filters={state.filters}
                            keyword={state.keyword}
                            showHistory={false}
                            autoFocus
                        />
                    </div>
                </div>
                <div className="tw-grid tw-grid-flow-row-dense lg:tw-grid-cols-3 grid-rows-2 tw-gap-4 tw-overflow-scroll tw-h-full">
                    <SearchResultsBox
                        keyword={state.keyword}
                        user={true}
                        items={resources}
                        label={"Publications"}
                        routeKey={"knowledge-view"}
                        setOpen={setOpen}
                        icon={<Library size={18} color={theme.colour.gray} />}
                    />

                    <SearchResultsBox
                        keyword={state.keyword}
                        user={true}
                        items={news}
                        label={"News"}
                        routeKey={"news-and-events-view-news"}
                        setOpen={setOpen}
                        icon={<Newspaper size={18} color={theme.colour.gray} />}
                    />

                    <SearchResultsBox
                        keyword={state.keyword}
                        user={true}
                        items={events}
                        label={"Events"}
                        routeKey={"capacity-building-view"}
                        setOpen={setOpen}
                        icon={<Calendar size={18} color={theme.colour.gray} />}
                    />

                    <SearchResultsBox
                        keyword={state.keyword}
                        user={true}
                        items={institutions}
                        label={"Members"}
                        routeKey={user ? "institution-view" : "member"}
                        setOpen={setOpen}
                        icon={<UserRound size={18} color={theme.colour.gray} />}
                    />

                    {user ? (
                        <SearchResultsBox
                            keyword={state.keyword}
                            user={user}
                            items={historicalDeals}
                            label={"Historical Deals"}
                            routeKey={"deals-view"}
                            setOpen={setOpen}
                            icon={
                                <DatabaseZap
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    ) : (
                        <FakeSearchResultsBox
                            user={user}
                            items={fakeSearchResults?.items}
                            label={"Historical Deals"}
                            routeKey={"deals-view"}
                            setOpen={setOpen}
                            icon={
                                <DatabaseZap
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    )}
                    {user ? (
                        <SearchResultsBox
                            keyword={state.keyword}
                            user={user}
                            items={liveDeals}
                            label={"Fundraising Deals"}
                            routeKey={"deals-view"}
                            setOpen={setOpen}
                            icon={
                                <HandCoins
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    ) : (
                        <FakeSearchResultsBox
                            user={user}
                            items={fakeSearchResults?.items}
                            label={"Fundraising Deals"}
                            routeKey={"deals-view"}
                            setOpen={setOpen}
                            icon={
                                <HandCoins
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    )}
                    {user ? (
                        <SearchResultsBox
                            keyword={state.keyword}
                            user={user}
                            items={historicalInstitutions}
                            label={"Investors"}
                            routeKey={"investor-data-view-institution"}
                            setOpen={setOpen}
                            icon={
                                <DatabaseZap
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    ) : (
                        <FakeSearchResultsBox
                            user={user}
                            items={fakeSearchResults?.items}
                            label={"Investors"}
                            routeKey={"investor-data-view-institution"}
                            setOpen={setOpen}
                            icon={
                                <DatabaseZap
                                    size={18}
                                    color={theme.colour.gray}
                                />
                            }
                        />
                    )}
                </div>
                <SheetFooter></SheetFooter>
                <style jsx global>
                    {`
                        .sheet {
                            background-color: hsl(var(--background)) !important;
                        }
                        .filters-bar {
                            background-color: hsl(var(--background)) !important;
                        }
                        a {
                            color: ${theme.colour.gray900};
                        }
                        h4 {
                            color: ${theme.colour.gray} !important;
                        }
                    `}
                </style>
            </SheetContent>
        </Sheet>
    );

    function SearchResultsBox({
        keyword,
        user,
        label,
        items,
        routeKey,
        setOpen,
        icon
    }) {
        return items?.length ? (
            <div>
                <div className="tw-mb-2">
                    <h4 className="tw-text-lg tw-flex tw-items-center">
                        {icon && <span className="tw-mr-2">{icon}</span>}
                        {label}
                    </h4>
                    <span className="tw-flex tw-w-[50%] tw-h-1 tw-border-b-2 tw-border-secondary"></span>
                </div>
                <SearchResultsList
                    items={items}
                    routeKey={routeKey}
                    setOpen={setOpen}
                />
            </div>
        ) : (
            <div>
                <div className="tw-mb-2">
                    <h4 className="tw-text-lg tw-flex tw-items-center">
                        {icon && <span className="tw-mr-2">{icon}</span>}
                        {label}
                    </h4>
                    <span className="tw-flex tw-w-[50%] tw-h-1 tw-border-b-2 tw-border-secondary"></span>
                </div>
                <p>
                    We couldn't find any matches for{" "}
                    <i className="tw-text-lg tw-text-primary">"{keyword}"</i> in
                    our <span className="tw-text-lg tw-text-user">{label}</span>
                </p>
            </div>
        );
    }

    function FakeSearchResultsBox({
        user,
        label,
        items,
        routeKey,
        setOpen,
        icon
    }) {
        return (
            <div className="tw-relative">
                <div className="tw-mb-2">
                    <h4 className="tw-text-lg tw-flex tw-items-center">
                        {icon && <span className="tw-mr-2">{icon}</span>}
                        {label}
                    </h4>
                    <span className="tw-flex tw-w-[50%] tw-h-1 tw-border-b-2 tw-border-secondary"></span>
                </div>
                <div className="tw-relative tw-flex  tw-items-center">
                    <SearchResultsList
                        items={items}
                        routeKey={routeKey}
                        setOpen={setOpen}
                    />
                    <div className="tw--mx-4 tw-backdrop-blur-sm tw-absolute tw-z-[102] tw-inset-0 "></div>
                    <span className="tw-z-[103] tw-absolute tw-justify-center tw-flex tw-w-full">
                        <Lock color={theme.colour.secondary} size={34} />
                    </span>
                </div>
                <div className="tw-text-center">
                    <h5 className="tw-text-lg tw-text-secondary">
                        This content is members only
                    </h5>
                    <a href={"/login"} target="_blank" className="tw-text-sm">
                        login
                    </a>{" "}
                    |{" "}
                    <a href={"/sign-up"} target="_blank" className="tw-text-sm">
                        register
                    </a>
                </div>
            </div>
        );
    }

    function SearchResultsList({ items, routeKey, setOpen }) {
        return (
            <ul className="tw-flex tw-flex-col tw-py-4 tw-pr-2">
                {items?.map(({ node }) => {
                    // const routeKey = "deals-view";
                    return (
                        <SearchResultLink
                            node={node}
                            routeKey={routeKey}
                            setOpen={setOpen}
                        />
                    );
                })}
            </ul>
        );
    }

    function SearchResultLink({
        node,
        routeKey,
        setOpen
    }: {
        node: any;
        routeKey: string;
        setOpen: (open: boolean) => void;
    }) {
        return (
            <li
                key={node.id}
                className="tw-text-sm tw-pb-2 tw-underline tw-underline-offset-2 tw-decoration-1"
            >
                <Link
                    routeKey={routeKey}
                    params={{
                        id: node.id
                    }}
                    query={
                        routeKey === "member"
                            ? {
                                  keyword: btoa(node.title)
                              }
                            : undefined
                    }
                    label={node.title}
                    onClick={() => setOpen(false)}
                    // target="_blank"
                >
                    <div>{node.title}</div>
                </Link>
            </li>
        );
    }
}
